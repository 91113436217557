<template lang="pug">
  .page
    layoutContainer
      .order-list(class="search-tab" v-if="enabled || isOperator")
        .options
          span 筛选
          el-select(v-model="orderType" placeholder="请选择" value-key="id")
            el-option(key="1" label="我的订单" value="1")
            el-option(key="2" label="我代销的订单" value="2" v-if="enabled")
            el-option(key="3" label="全站订单" value="3" v-if="isOperator")
        .options
          span 搜索
          el-select(v-model="queryType" placeholder="请选择" value-key="id" style="width: 180px;margin-right: 20px;")
            el-option(key="1" label="订单编号" value="1")
            el-option(key="2" label="所属人id" value="2" v-if="orderType === '3' || orderType === '2'")
            el-option(key="3" label="代销人id" value="3" v-if="orderType === '3' || orderType === '2'")
            el-option(key="4" label="代销单号" value="4" v-if="orderType === '3' || orderType === '2'")
            el-option(key="5" label="站外单号" value="5" v-if="orderType === '3'")
          el-input(v-model="queryContent" placeholder="输入关键字" style="width: 230px;")
          img.search-icon(src="/img/search-icon.png" @click="searchBtnClick")
      .order-list(v-for="item in list" :key="item.orderCode")
        .header
          span 订单号：{{item.orderCode}}
          span {{item.createTime}}
        .course-detail(v-for="(opt,j) in item.items" :key="j" @click="checkDetail(opt, item)" :style="{cursor: `${item.productBusiness === '知识' ? 'pointer' : ''}`}")
          span.name {{ item.productBusiness === '会员' ? opt.productName : `产品名称：${opt.productName}`}}
          span.price ￥{{opt.totalPrice}}
        .course-detail(style="flex-direction: column; justify-content: flex-start;align-items: flex-start;" v-if="showOrderDetail(item)")
          .options-order-detail(v-if="(item.userName && (hasOrderType || isOperator)) || orderType === '2'")
            .label 所属用户ID
            .value {{item.userName}}
          .options-order-detail(v-if="item.agentSaleOrderId")
            .label 代销单号
            .value {{ item.agentSaleOrderId }}
          .options-order-detail(v-if="item.agentSaleUserName")
            .label 代销用户ID
            .value {{ item.agentSaleUserName }}
          .options-order-detail(v-if="item.outSideOrderId")
            .label 站外单号
            .value {{ item.outSideOrderId }}
          .options-order-detail(v-if="item.belongPlatform")
            .label 推广渠道
            .value {{ item.belongPlatform }}
          .options-order-detail(v-if="item.promoteType")
            .label 推广方式
            .value {{ item.promoteType }}
          .options-order-detail(v-if="item.platformUserId")
            .label 主播平台号
            .value {{ item.platformUserId }}
          .options-order-detail(v-if="item.activityDeductPrice && item.activityDeductPrice !== '0.00'")
            .label 活动抵扣
            .value -￥{{ item.activityDeductPrice }}
          .options-order-detail(v-if="item.couponDeductPrice && item.couponDeductPrice !== '0.00'")
            .label 用券抵扣
            .value -￥{{ item.couponDeductPrice }}
          .options-order-detail(v-if="item.bargainDeductPrice && item.bargainDeductPrice !== '0.00'")
            .label 砍价抵扣
            .value -￥{{ item.bargainDeductPrice }}
          .options-order-detail(v-if="item.togetherBuyDeductPrice && item.togetherBuyDeductPrice !== '0.00'")
            .label 拼团抵扣
            .value -￥{{ item.togetherBuyDeductPrice }}
          .options-order-detail(v-if="item.promoteDeductPrice && item.promoteDeductPrice !== '0.00'")
            .label 推广抵扣
            .value -￥{{ item.promoteDeductPrice }}
        .btn-list
          span.reject-apply(v-if="item.togetherBuyStatus === 1") 拼团中
          span.reject-apply(v-if="item.togetherBuyStatus === 2") 拼团成功
          span.reject-apply(v-if="item.togetherBuyStatus === 3") 拼团失败
          //- 进程状态   展示类型   不可操作    线框 
          //- 申请被拒绝/退款失败   红线框  
          span.apply-reject(v-if="needApply(item) && item.refundApplyStatus === 2") 退款申请已拒绝 
          span.reject-apply(v-if="needApply(item) && item.refundApplyStatus === 0") 退款申请处理中
          span.reject-apply(v-if="item.payTimeoutFlag") 支付超时 
          span.reject-apply(v-if="item.refundStatus === 0") 退款中
          span.reject-apply(v-if="item.refundStatus === 1") 已退款
          span.apply-reject(v-if="item.refundStatus === 2") 退款失败
          //- 可操作都是按钮
          //- 非视频 需要 申请退款
          .relation(v-if="(needApply(item) || item.agentSaleOrderId) && (item.refundApplyFlag && (item.refundApplyStatus === null  ||item.refundStatus === 1)) && (item.refundApplyStatus !== 2 && item.refundStatus !== 1)" @click="refund(item, 1)") 申请退款
          //- 标准订单可  查看详细  
          .relation(v-if="isStandard(item)" @click="goToClass(item)") 查看详细 {{ isStandard(item.productBusiness) }}
          //- 视频类订单 可以 去付款、退款
          .relation(v-if="isTechVideo(item) && item.refundFlag && item.refundStatus !== 1 && !item.agentSaleOrderId" @click="refund(item)") 退款
          .relation(v-if="item.payFlag" @click="goPay(item)") 去付款
          //- 所有订单都可以  申请开票、联系客服   
          .relation(v-if="item.invoiceApplyFlag" @click="applyInvoice(item)") 申请开票
          .relation(@click="relation") 联系客服
          el-popconfirm(v-if="item.outSideOrderId && item.refundFlag == true && item.refundStatus == null " confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认置已退款么" @confirm="changeStatus(item, 1)" )
            .relation(slot="reference") 置已退款
          el-popconfirm(v-if="(item.agentSaleOrderId !== '') && item.agreeRefundFlag && item.refundApplyStatus !== 2 && item.refundStatus !== 1" confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" :title="`${item.productBusiness === '知识' ? '确定同意退款吗?' : '确认同意退款吗？（同意退款后将向客服发起退款申请）'}`" @confirm="changeStatus(item, 2)")
            .relation(slot="reference") 同意退款
          span.total_price 总额
            span.price(style="font-weight: bold !important;") ￥{{item.discountTotalPrice}}
      el-pagination(background layout="prev, pager, next" :total="total" @current-change="currentChange")
  </template>

<script>
import {orderList, rejectedApply, passedApply, refund} from '@/api/subject'
import {refundApplication} from '@/api/product'
import {applyInvoice} from '@/api/background'
import {checkAuth} from '@/api/product'
import {getAgentSaleAuth} from '../../api/orderAccount'

export default {
  name: 'pay',
  // components: {commonHeader},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      orderType: '1',
      queryType: '1',
      queryContent: '',
      hasOrderType: false,
      isOperator: false,
      enabled: false,
      isFirstInit: true
    }
  },
  created() {
    this.orderType = this.$route.query.orderType !== undefined ? this.$route.query.orderType.toString() : '1'
    checkAuth({and: true, authList: [1]}).then(res => {
      this.isOperator = res.code === 200
    })
    this.$nextTick(() => {
      this.queryContent = this.$route.query.orderId || ''
      this.queryType = this.$route.query.queryType || ''
      this.hasOrderType = this.$route.query.orderType ? true : false
      this.isFirstInit = false
      this.getList()
      getAgentSaleAuth().then(res => {
        this.enabled = res.data.enabled === 1 ? true : false
      })
    })
  },
  watch: {
    orderType() {
      if (this.isFirstInit) return
      this.currentChange(1)
      this.queryType = ''
      this.queryContent = ''
    }
    // queryContent() {
    //   this.currentChange(1)
    // }
  },
  methods: {
    showOrderDetail(item) {
      return (
        (item.userName && (this.orderType === '1' || this.hasOrderType) && this.isOperator) ||
        this.orderType === '2' ||
        item.agentSaleOrderId ||
        item.agentSaleUserName ||
        item.outSideOrderId ||
        item.belongPlatform ||
        item.promoteType ||
        item.platformUserId ||
        (item.activityDeductPrice && item.activityDeductPrice !== '0.00') ||
        (item.couponDeductPrice && item.couponDeductPrice !== '0.00') ||
        (item.bargainDeductPrice && item.bargainDeductPrice !== '0.00') ||
        (item.togetherBuyDeductPrice && item.togetherBuyDeductPrice !== '0.00') ||
        (item.promoteDeductPrice && item.promoteDeductPrice !== '0.00')
      )
    },
    searchBtnClick() {
      if (this.queryType === '') {
        this.$message.error('请选择搜索项')
        return
      }
      if (this.queryContent === '') {
        this.$message.error('请输入关键词')
        return
      }
      this.currentChange(1)
    },
    needApply(info) {
      return info.productBusiness === '会员' || info.productBusiness === '标准'
    },
    isStandard(info) {
      return info.productBusiness === '标准'
    },
    isTechVideo(info) {
      return info.productBusiness === '知识'
    },
    relation() {
      this.$router.push('/connectionQuanqing')
    },
    goToClass(info) {
      if (info.productBusiness === '会员') return
      if (info.productBusiness === '标准') {
        this.$router.push({
          path: '/quanqingStandard',
          query: {
            orderCode: info.orderCode
          }
        })
        return
      }
      this.$router.push({
        path: '/techVideoDetail',
        query: {id: info.productId}
      })
    },
    changeStatus(info, type) {
      console.log('%c [ info ]-194', 'font-size:13px; background:pink; color:#bf2c9f;', info)
      if (type === 1) {
        rejectedApply(info.orderCode).then(res => {
          if (res.code === 200) {
            this.$message.success('操作成功')
            this.getList()
            return
          }
          this.$message.error(res.msg)
        })
      } else {
        passedApply(info.orderCode).then(res => {
          if (res.code === 200) {
            this.$message.success('操作成功')
            this.getList()
            return
          }
          this.$message.error(res.msg)
        })
      }
    },
    checkDetail(info, item) {
      console.log('item: ', item)
      console.log('item.productBusiness =: ', item.productBusiness === '知识')
      if (item.productBusiness === '知识') this.$router.push(`/techVideoDetail?id=${info.productId}`)
    },
    refund(info, type) {
      let toastMsg = ''
      if (info.productBusiness === '会员') {
        toastMsg = '申请审核0-2天通过后，预计0-7天内退款成功，并收回对应会员时长。确定申请退款吗？'
      } else if (info.productBusiness === '标准') {
        toastMsg = '退款申请将在0-2天内由客服审核，审核通过后才可退款。确定申请退款吗？'
      } else {
        toastMsg = '确认要退款吗？'
      }
      if (info.agentSaleOrderId !== '') {
        toastMsg =
          info.productBusiness === '知识'
            ? '代销订单需要代销人和所属人都同意才能退款成功，您申请后请通知另一人来此订单点击同意退款。如有问题可让客服介入。确定申请退款吗？'
            : '您点申请退款后，需通知此订单的另一人（代销人或所属人）来此订单点击同意退款，才会真正向客服发起退款申请，客服审批后才可退款成功。确定申请退款吗？'
      }
      this.$confirm(toastMsg)
        .then(() => {
          if (info.agentSaleOrderId) {
            refundApplication({
              orderCode: info.orderCode
            }).then(res => {
              if (res.code === 200) {
                this.$dialog.toast(
                  info.productBusiness === '视频' ? '已成功提交退款申请' : '退款申请已提交，系统处理中',
                  {
                    delay: 4
                  }
                )
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
            return
          }
          if (info.productBusiness === '会员' || info.productBusiness === '标准') {
            refundApplication({
              orderCode: info.orderCode
            }).then(res => {
              if (res.code === 200) {
                this.$dialog.toast(info.agentSaleOrderId ? '已成功提交退款申请' : '退款申请已提交', {
                  delay: 4
                })
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            refund(info.orderCode).then(res => {
              if (res.code === 200) {
                this.$dialog.toast('退款申请已提交', {delay: 4})
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
        .catch(() => {})
    },
    applyInvoice(info) {
      this.$confirm('是否申请开发票？').then(() => {
        applyInvoice({orderCode: info.orderCode}).then(res => {
          if (res.code === 200) {
            this.$dialog.toast('您的开票申请在等待客服处理中，请耐心等待')
            this.getList()
          }
        })
      })
    },
    currentChange(val) {
      this.page = val
      this.getList()
    },
    getList() {
      orderList({
        pageNum: this.page,
        pageSize: 10,
        orderType: this.orderType,
        queryType: this.queryType,
        queryContent: this.queryContent
      }).then(res => {
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    goPay(item) {
      this.$router.push({
        path: '/pay',
        query: {
          courseId: item.productId,
          orderId: item.orderCode
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 100%;
  .search-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px !important;
    .options {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        margin-right: 20px;
      }
    }
  }
  .order-list {
    max-width: 1200px;
    min-width: 960px;
    padding: 0 30px;
    background: #ffffff;
    border-radius: 8px;
    margin: 20px auto;
  }
  .header {
    height: 107px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 37px;
    }
  }
  .btn-list {
    padding: 30px 0 50px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: red;
      line-height: 40px;
      margin-left: 5px;
    }
    .refund-agreement {
      font-size: 20px;
      font-weight: 400;
      cursor: pointer;
      color: #5ad3cd;
      line-height: 40px;
      text-decoration: underline;
    }
    .apply-reject {
      padding-left: 20px;
      padding-right: 20px;
      line-height: 38px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #ff724c;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff724c;
      text-align: center;
    }
    .reject-apply {
      padding-left: 20px;
      padding-right: 20px;
      height: 40px;
      line-height: 38px;
      border-radius: 20px;
      border: 1px solid #d3d3d3;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      text-align: center;
    }
    .refund-way {
      width: 100px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #d3d3d3;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      text-align: center;
    }
    .apply-refund {
      width: 100px;
      height: 40px;
      background: #ff724c;
      border-radius: 20px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }

    .refund {
      width: 100px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #979797;
      font-size: 18px;
      font-weight: 500;
      color: #666666;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      margin-left: 10px;
    }
    .go-pay {
      width: 100px;
      height: 40px;
      border-radius: 20px;
      background: #ff724c;
      border-radius: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      margin-left: 10px;
      cursor: pointer;
    }
    .relation {
      width: 100px;
      height: 40px;
      border-radius: 20px;
      background: #5ad3cd;
      border-radius: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .course-detail {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(239, 239, 239, 0.5);
    border-radius: 8px;
    padding: 30px;
    .name {
      width: 1000px;
      font-size: 24px;
      line-height: 33px;
      margin-right: 40px;
      overflow: hidden;
    }
    span {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    .price {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff724c;
    }
  }
}
.options-order-detail {
  height: 40px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
  .label {
    width: 100px;
    color: #acacac;
    margin-right: 20px;
  }
}
.total_price {
  padding-left: 15px;
  font-size: 24px !important;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400 !important;
  color: #666666 !important;
  .price {
    font-size: 30px !important;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500 !important;
    color: #ff724c !important;
  }
}
.search-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 20px;
}
.el-dialog {
  overflow: hidden;
}
</style>
